<template>
  <!--  <vue3-quill-editor :disabled="true" theme="snow" content-type="html" enable :content="desc" />-->
<!--  {{ entity.content }}-->
  <el-form-item>
    <QuillEditor ref="quill-editor" v-model:content="entity.content" :options="editorOption" contentType="html"
                 style="margin-bottom: 10px"/>
  </el-form-item>
</template>

<!-- 本页的js -->
<script>
// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
  ["blockquote", "code-block"], // 引用
  [{list: 'ordered'}, {list: 'bullet'}], // 有序、无序列表
  [{script: "sub"}, {script: "super"}], // 上标/下标
  [{indent: '-1'}, {indent: '+1'}], // 缩进
  [{direction: 'rtl'}], // 文本方向
  [{size: ['small', false, 'large', 'huge']}], // 字体大小
  [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题
  [{color: []}, {background: []}], // 字体颜色、字体背景颜色
  [{font: []}], // 字体种类
  [{align: []}], // 对齐方式
  ['clean'], // 清除文本格式
  ['link', 'image', 'video'] // 链接、图片、视频
]
import {QuillEditor, Quill} from '@vueup/vue-quill'
import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'
import quillTool from '@/utils/quillTool'
Quill.register(quillTool, true)
Quill.register('modules/ImageExtend', ImageExtend)
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: 'EditDictDialog',
  components: {QuillEditor},
  data() {
    return {
      entity: {
        title: null,
        content: null
      },
      submitLoading: false,
      dialogTableVisible: false,
      titleMap: {
        'create': "新增",
        'update': "修改"
      },
      titleStatus: 'create',
      rules: {
        title: [
          {required: true, message: '请输入概况标题', trigger: 'blur'},
          {min: 1, max: 100, message: '长度在100个字符以内', trigger: 'blur'}
        ]
      },
      isDisabledName: false,
      editorOption: {
        theme: 'snow',
        placeholder: '请输入内容',
        modules: {
          imageDrop: true,  // 图片粘贴
          // 如果使用图片可调整大小，加入如下配置
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          },
          // 处理点击工具栏图片按钮，上传图片base64位转换成服务器图片url
          ImageExtend: {
            loading: true, // 可选参数 是否显示上传进度和提示语
            name: 'file_name', // 参数名
            action: '', // 服务器地址，如果为空则采用base64插入图片
            headers: xhr => { // 设置请求头参数（选填）
              xhr.setRequestHeader('Content-Type', 'multipart/form-data')
            },
            response: res => {
              console.log(res)
              return res.data.imgPath
            },
            size: 8, // 可选参数 图片大小,图片不能超过8M
            sizeError: () => {
              this.$message.error('粘贴图片大小不能超过8MB!')
            }
          },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                QuillWatch.emit(this.quill.id)
              },
              link: function (value) {
                if (value) {
                  var href = prompt('请输入链接地址：')
                  this.quill.format('link', href)
                } else {
                  this.quill.format('link', false)
                }
              },
              video: function (value) {
                if (value) {
                  var href = prompt('请输入视频链接：')
                  this.quill.format('video', href)
                } else {
                  this.quill.format('video', false)
                }
              },
            }
          }
        }
      }
    }
  },
  methods: {
    openEditDialog(dialogStatus, titleStatus, row) {
      this.isDisabledName = false
      this.dialogTableVisible = dialogStatus;
      this.titleStatus = titleStatus;
      if (titleStatus === "create") {
        this.cleanData();
      } else if (titleStatus === "update") { // 更新不校验饮片名称是否存在
        this.isDisabledName = true
      }
      if (row) {
        this.entity = Object.assign({}, row);
        this.$nextTick(() => {
          this.$refs['quill-editor'].setHTML(this.entity.content)
        })
      }
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      })

    },

    close() {
      this.dialogTableVisible = false;
      this.cleanData();
      this.titleStatus = 'create'
      this.$nextTick(() => {
        this.$refs['quill-editor'].setHTML('')
        this.$refs['form'].clearValidate()
      })
    }

  }
}
</script>

<!-- 本页的css -->
<style scoped>
/deep/ .el-form-item__content {
  display: inline
}

/deep/ .el-dialog__footer {
  margin-top: 10px;
}

/deep/ .ql-container {
  height: 300px;
  line-height: normal;
  width: auto;
}

/deep/ span.ql-size {
  max-width: 80px !important;
}

/deep/ .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}

/deep/ .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

/deep/ .ql-tooltip[data-mode="video"] {
  left: 0 !important;
}

/deep/ .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

/deep/ .ql-picker.ql-size .ql-picker-label::before,
.ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}

/deep/ .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}

/deep/ .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}

/deep/ .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

/deep/ .ql-picker.ql-header .ql-picker-label::before,
.ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}

/deep/ .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}

/deep/ .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}

/deep/ .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}

/deep/ .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}

/deep/ .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}

/deep/ .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

/deep/ .ql-picker.ql-font .ql-picker-label::before,
.ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}

/deep/ .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}

/deep/ .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
