import $http from "./index";
import {PLATFROM_CONFIG} from '../../public/config'
let base = PLATFROM_CONFIG.baseURL;


export const getNoteConfigurationList = () => {
    return $http.get(
        `${base}/app/info/evaluationconfiguration/back/getNoteEvalConfiguration`,
        {
            timeout: 1000,
        }
    )
};

//修改
export const UpdateEvalContent = (data) => {
    return $http.post(
        `${base}/app/info/evaluationconfiguration/updateEvalContent`,
        data
    );
};
export const getNote7ConfigurationList = () => {
    return $http.get(
        `${base}/app/info/evaluationconfiguration/back/getNote7EvalConfiguration`,
        {
            timeout: 1000,
        }
    )
};
export const getNote8ConfigurationList = () => {
    return $http.get(
        `${base}/app/info/evaluationconfiguration/back/getNote8EvalConfiguration`,
        {
            timeout: 1000,
        }
    )
};
export const getvipblurb = () => {
    return $http.get(
        `${base}/app/info/evaluationconfiguration/back/getvipblurb`,
        {
            timeout: 1000,
        }
    )
};

export const getRights = () => {
    return $http.get(
        `${base}/app/info/evaluationconfiguration/back/getRights`,
        {
            timeout: 1000,
        }
    )
};
