let OSS = require('ali-oss');
const path = require("path");

let OSSclient = new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: 'oss-cn-beijing',
    // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
    accessKeyId: 'LTAI5t7kTa1gD58ZCkbaHPDT',
    accessKeySecret: '4mPDVoOHonT3Alc5GamWA2DDilr31P',
    // 填写Bucket名称。
    bucket: 'fengheplaza'
    //ucket: 'test-wechat'
});

const headers = {
    // 指定Object的存储类型。
    'x-oss-storage-class': 'Standard',
    // 指定Object的访问权限。
    'x-oss-object-acl': 'private',
    // 通过文件URL访问文件时，指定以附件形式下载文件，下载后的文件名称定义为example.jpg。
    // 'Content-Disposition': 'attachment; filename="example.jpg"'
    // 设置Object的标签，可同时设置多个标签。
    'x-oss-tagging': 'Tag1=1&Tag2=2',
    // 指定PutObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
    'x-oss-forbid-overwrite': 'true',
};

export const putToOSS = async (ObjName, fileUrl) => {
    try {
        let result = await OSSclient.put(`${ObjName}`, fileUrl)
        // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称
        return result
    } catch (e) {
        console.log(e)
    }
}

// 上传成功之后，转换真实的地址
export const signatureUrl= async (ObjName) => {
    try {
        let result = await OSSclient.signatureUrl(`${ObjName}`)
        return result
    } catch (e) {
        console.log(e)
    }
}

export const getFileNameUUID = () => {
    function rx() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }

    return `${+new Date()}_${rx()}${rx()}`
}
//获取文件扩展名
export const getFileExtensions = (name) => {
    return  name.substring(name.lastIndexOf("."));//.jpg
}
/**
 * 上传图片文件
 */
export const getUpImgURL = params => {
    console.log("---getUpImgURL---");
    console.log(params.get('file'));
    console.log(params);

    var file = params.get('file');//用户上传的图片信息
    var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);//获取后缀名
    var filename = file.uid+'.'+fileExtension;//保存到obs上的路径

    //上传的方法
    const result = OSSclient.put(filename,file);
    console.log(result);
    return "https://oss-cn-shanghai.aliyuncs.com/"+filename; //返回上传的路径
    //   var imagesFilePath = [];
    // if (params.length != 0) {

    //   createFileFolder('image', params[0].parentFileFolder);
    //   for (var i = 0; i < params.length; i++) {
    //     imagesFilePath[i] = uploadImageFile(params[i]);
    //   }
    // }

    // return imagesFilePath;

}



