import $http from "./index";
import {PLATFROM_CONFIG} from '../../public/config'
let base = PLATFROM_CONFIG.baseURL;
// export const getvipmanage = (data) => {
//     return $http.post(
//         `${base}/app/info/vipmanage/back/getVipManage`,
//         data
//     );
// };
export const getvipmanage = (data) => {
    return $http.get(
        `${base}/app/info/vipmanage/back/getvipmanage`,
        {
            params: data,
        }
    );
};
export const getequity = () => {
    return $http.get(
        `${base}/app/info/vipequity/back/geEquity`,
        {
            timeout: 1000,
        }
    );
};
export const deleVipManage = (data) => {
    return $http.get(
        `${base}/app/info/vipmanage/back/delete`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
export const VipManagegyId = (data) => {
    return $http.get(
        `${base}/app/info/vipmanage/back/gyId`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
export const addVipManage = (data) => {
    return $http.post(
        `${base}/app/info/vipmanage/back/new`,
        data
    );
}
export const updateVipManage = (data) => {
    return $http.post(
        `${base}/app/info/vipmanage/back/update`,
        data
    );
}
export const addVipManagEquity = (data) => {
    return $http.post(
        `${base}/app/info/vipmanage/back/setdescpic`,
        data
    );
}
